import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Set the current value of the select to the param with the same name. This is a workaround for a bug that prevents
  // shoelace from setting it properly on page load when using stimulus.
  connect() {
    const currentParams = new URL(window.location.toLocaleString()).searchParams;
    if (currentParams.has(this.element.name)) {
      this.element.value = currentParams.get(this.element.name);
    }
  }

  autoSubmit(event) {
    this.element.getForm().requestSubmit();
  }
}
